import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeAnchorHeadingSkeleton } from "./TypeAnchorHeading";
import type { TypeBannerComponentSkeleton } from "./TypeBannerComponent";
import type { TypeCardRowComponentSkeleton } from "./TypeCardRowComponent";
import type { TypeCustomerServiceComponentSkeleton } from "./TypeCustomerServiceComponent";
import type { TypeFaqComponentSkeleton } from "./TypeFaqComponent";
import type { TypeFeaturedProductsComponentSkeleton } from "./TypeFeaturedProductsComponent";
import type { TypeHeroCarouselComponentSkeleton } from "./TypeHeroCarouselComponent";
import type { TypeProductCardsComponentSkeleton } from "./TypeProductCardsComponent";
import type { TypeProductModalComponentSkeleton } from "./TypeProductModalComponent";
import type { TypeRichTextComponentSkeleton } from "./TypeRichTextComponent";
import type { TypeSeoSkeleton } from "./TypeSeo";
import type { TypeSubscriptionComponentSkeleton } from "./TypeSubscriptionComponent";
import type { TypeUspBarComponentSkeleton } from "./TypeUspBarComponent";

/**
 * Fields type definition for content type 'TypeContentPage'
 * @name TypeContentPageFields
 * @type {TypeContentPageFields}
 * @memberof TypeContentPage
 */
export interface TypeContentPageFields {
    /**
     * Field type definition for field 'title' (Title)
     * @name Title
     * @localized true
     */
    title: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'menuTitle' (Menu Title)
     * @name Menu Title
     * @localized false
     */
    menuTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'slug' (Slug)
     * @name Slug
     * @localized true
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'seoMetadata' (SEO Metadata)
     * @name SEO Metadata
     * @localized false
     */
    seoMetadata?: EntryFieldTypes.EntryLink<TypeSeoSkeleton>;
    /**
     * Field type definition for field 'header' (Header)
     * @name Header
     * @localized false
     */
    header?: EntryFieldTypes.EntryLink<TypeBannerComponentSkeleton>;
    /**
     * Field type definition for field 'showMenu' (Show Menu)
     * @name Show Menu
     * @localized false
     * @summary Is it necessary to display the menu
     */
    showMenu?: EntryFieldTypes.Boolean;
    /**
     * Field type definition for field 'content' (Content)
     * @name Content
     * @localized false
     */
    content?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeAnchorHeadingSkeleton | TypeBannerComponentSkeleton | TypeCardRowComponentSkeleton | TypeCustomerServiceComponentSkeleton | TypeFaqComponentSkeleton | TypeFeaturedProductsComponentSkeleton | TypeHeroCarouselComponentSkeleton | TypeProductCardsComponentSkeleton | TypeProductModalComponentSkeleton | TypeRichTextComponentSkeleton | TypeSubscriptionComponentSkeleton | TypeUspBarComponentSkeleton>>;
}

/**
 * Entry skeleton type definition for content type 'contentPage' (📄 Content Page)
 * @name TypeContentPageSkeleton
 * @type {TypeContentPageSkeleton}
 * @author 3wpLHpdye9A1HI6MK88Utd
 * @since 2023-09-21T11:18:56.687Z
 * @version 87
 */
export type TypeContentPageSkeleton = EntrySkeletonType<TypeContentPageFields, "contentPage">;
/**
 * Entry type definition for content type 'contentPage' (📄 Content Page)
 * @name TypeContentPage
 * @type {TypeContentPage}
 * @author 3wpLHpdye9A1HI6MK88Utd
 * @since 2023-09-21T11:18:56.687Z
 * @version 87
 */
export type TypeContentPage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeContentPageSkeleton, Modifiers, Locales>;

export function isTypeContentPage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeContentPage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'contentPage'
}
