import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeAnchorHeadingSkeleton } from "./TypeAnchorHeading";
import type { TypeBannerComponentSkeleton } from "./TypeBannerComponent";
import type { TypeCardRowComponentSkeleton } from "./TypeCardRowComponent";
import type { TypeCustomerServiceComponentSkeleton } from "./TypeCustomerServiceComponent";
import type { TypeFaqComponentSkeleton } from "./TypeFaqComponent";
import type { TypeFeaturedProductsComponentSkeleton } from "./TypeFeaturedProductsComponent";
import type { TypeHeroCarouselComponentSkeleton } from "./TypeHeroCarouselComponent";
import type { TypeLinkBlockItemSkeleton } from "./TypeLinkBlockItem";
import type { TypeProductCardsComponentSkeleton } from "./TypeProductCardsComponent";
import type { TypeProductModalComponentSkeleton } from "./TypeProductModalComponent";
import type { TypeRichTextComponentSkeleton } from "./TypeRichTextComponent";
import type { TypeSeoSkeleton } from "./TypeSeo";
import type { TypeSubscriptionComponentSkeleton } from "./TypeSubscriptionComponent";
import type { TypeUspBarComponentSkeleton } from "./TypeUspBarComponent";

/**
 * Fields type definition for content type 'TypeContentWithFormPage'
 * @name TypeContentWithFormPageFields
 * @type {TypeContentWithFormPageFields}
 * @memberof TypeContentWithFormPage
 */
export interface TypeContentWithFormPageFields {
    /**
     * Field type definition for field 'title' (Title)
     * @name Title
     * @localized true
     */
    title: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'menuTitle' (Menu Title)
     * @name Menu Title
     * @localized false
     */
    menuTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'slug' (Slug)
     * @name Slug
     * @localized true
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'seoMetadata' (SEO Metadata)
     * @name SEO Metadata
     * @localized false
     */
    seoMetadata?: EntryFieldTypes.EntryLink<TypeSeoSkeleton>;
    /**
     * Field type definition for field 'header' (Header)
     * @name Header
     * @localized false
     */
    header?: EntryFieldTypes.EntryLink<TypeBannerComponentSkeleton>;
    /**
     * Field type definition for field 'formDescription' (Form Description)
     * @name Form Description
     * @localized false
     */
    formDescription: EntryFieldTypes.EntryLink<TypeRichTextComponentSkeleton>;
    /**
     * Field type definition for field 'formKey' (Form Key)
     * @name Form Key
     * @localized false
     * @summary Form key from formcraft
     */
    formKey: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'content' (Content)
     * @name Content
     * @localized false
     */
    content: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeAnchorHeadingSkeleton | TypeBannerComponentSkeleton | TypeCardRowComponentSkeleton | TypeCustomerServiceComponentSkeleton | TypeFaqComponentSkeleton | TypeFeaturedProductsComponentSkeleton | TypeHeroCarouselComponentSkeleton | TypeLinkBlockItemSkeleton | TypeProductCardsComponentSkeleton | TypeProductModalComponentSkeleton | TypeRichTextComponentSkeleton | TypeSubscriptionComponentSkeleton | TypeUspBarComponentSkeleton>>;
    /**
     * Field type definition for field 'anchorButtonTitle' (Anchor Button Title)
     * @name Anchor Button Title
     * @localized true
     */
    anchorButtonTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'anchorButtonText' (Anchor Button Text)
     * @name Anchor Button Text
     * @localized true
     */
    anchorButtonText: EntryFieldTypes.Symbol;
}

/**
 * Entry skeleton type definition for content type 'contentWithFormPage' (📄 Content With Form Page)
 * @name TypeContentWithFormPageSkeleton
 * @type {TypeContentWithFormPageSkeleton}
 * @author 0kZNfwixsbsTjcGzU3RIqE
 * @since 2024-10-21T11:27:43.559Z
 * @version 1
 */
export type TypeContentWithFormPageSkeleton = EntrySkeletonType<TypeContentWithFormPageFields, "contentWithFormPage">;
/**
 * Entry type definition for content type 'contentWithFormPage' (📄 Content With Form Page)
 * @name TypeContentWithFormPage
 * @type {TypeContentWithFormPage}
 * @author 0kZNfwixsbsTjcGzU3RIqE
 * @since 2024-10-21T11:27:43.559Z
 * @version 1
 */
export type TypeContentWithFormPage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeContentWithFormPageSkeleton, Modifiers, Locales>;

export function isTypeContentWithFormPage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeContentWithFormPage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'contentWithFormPage'
}
